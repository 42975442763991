
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');
/* vietnamese */
@font-face {
  font-family: 'DotDigital';
  font-style: normal;
  font-weight: 700;
  //font-display: swap;
  src: url(https://inxel.mx/fonts/enhanced_dot_digital-7.ttf) format('ttf');
  //unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/*Myriad Pro Bold

font-family: myriad-pro, sans-serif;

font-weight: 700;

font-style: normal;


Myriad Pro Bold Condensed

font-family: myriad-pro-condensed, sans-serif;

font-weight: 700;

font-style: normal;

*/
.slayers{
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.effect_slayer1{
  position: absolute;
  bottom: -10px;
  margin-left: -300px;
  width: 2500px;
  svg{
    animation: wave-bot 4s 0.1s infinite linear;
  }
}

.effect_slayer2{
  position: absolute;
  opacity: 0.4;
  bottom: 20px;
  margin-left: -520px;
  width: 2500px;
  svg{
    animation: wave-bot 4.5s 0.3s infinite linear;

  }
}
.effect_slayer3{
  position: absolute;
  opacity: 0.6;
  bottom: 40px;
  margin-left: -150px;
  width: 2500px;
  svg{
    animation: wave-bot 5s 0.5s infinite linear;
  }
}

@keyframes wave-bot {
  0% {
    transform: rotateZ(0deg) translate3d(0, 5%, 0) rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg) translate3d(0, 5%, 0) rotateZ(-360deg);
  }
}


@keyframes Agrandar {
  0%{
    transform: scale(.5);
  }
  100%{
    transform: scale(1);
  }
}

@keyframes Achicar {
  0%{
    transform: scale(1);
  }
  100%{
    transform: scale(.5);
  }
}
