.line1 {
  width: 50%;
  height: 100%;
  border-bottom: 3px solid #FF8A21;
  -webkit-transform: translateY(-20px) translateX(5px) rotate(15deg);
  position: absolute;
  /* top: -20px; */
}

.line2 {
  width: 112px;
  height: 47px;
  border-bottom: 1px solid green;
  -webkit-transform: translateY(20px) translateX(5px) rotate(-26deg);
  position: absolute;
  top: -33px;
  left: -13px;
}


