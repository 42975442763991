.Navbar-desktop {
  background-color: #fefefe;
}

.button-navup-desktop {
  border: 1px solid #fefefe;
  margin: 0 2px;
}

@keyframes growDown {
  0% {
    height: 70px;
  }
  100% {
    height: 50px;
  }
}
@keyframes growUp {
  0% {
    height: 50px;
  }
  100% {
    height: 70px;
  }
}

