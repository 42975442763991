* {
    --primaryColor: #5A3571;
    --secondaryColor: #FF8A21;
}

.App {
    text-align: center;
}

.App-logo {
    height: 20vmin;
    pointer-events: none;
    /* transition: all 2s ease-in-out;*/
    /* position: absolute;
     bottom: 30vmax;*/
    transform: translateY(0px);
    margin-right: 8px;
}

.Bounce-shadow {
    background: #ceb852;
    height: 10px;
    width: 17vmin;
    border-radius: 100%;
}

.Lampara {
    position: absolute;
    top: 0;
    height: 50px;
}

.Lampara, img {
    height: 13vmin;
}

@media (prefers-reduced-motion: no-preference) {
    .Bounce-shadow {
        animation: Bounce-shadow-animation 2.5s infinite linear;
    }

    .down {
        animation: App-logo-bounce 2.5s infinite linear;
    }
}

.App-header {

    background-color: black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    position: relative;
}

@keyframes App-logo-bounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes Bounce-shadow-animation {
    0% {
        width: 17vmin;
    }
    50% {
        width: 13vmin;
    }
    100% {
        width: 17vmin;
    }
}


.pay-history-button {
    padding: 1%;
    border-top: 1px solid #000000;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

}

.pay-history-button:hover {
    background-color: var(--secondaryColor);
    color: #fefefe;
}

.pay-history-button.active {
    background-color: var(--secondaryColor);
    color: #fefefe;
}

.pay-history-button-counter {
    background-color: var(--primaryColor);
    color: #fefefe;
    height: 20px;
    width: 20px;
    font-size: 15px;
    border-radius: 50%;

    display: flex;
    justify-content: center;

    align-items: center;
    margin-left: 3px;
}
